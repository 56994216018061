// extracted by mini-css-extract-plugin
export var congrats = "finish-module--congrats--U7MtS";
export var description = "finish-module--description--s17NQ";
export var extContainer = "finish-module--extContainer--ixV2q";
export var extDesc = "finish-module--extDesc--J-J9p";
export var extHead = "finish-module--extHead--zmRUA";
export var extension = "finish-module--extension--8xn0x";
export var head = "finish-module--head--vGppb";
export var img_bg = "finish-module--img_bg--SEeOA";
export var item = "finish-module--item--0-gzg";
export var list = "finish-module--list--AUlrf";
export var stocks = "finish-module--stocks--RhNkm";
export var stocksHead = "finish-module--stocksHead--YExS8";
export var subHead = "finish-module--subHead--Z-lWW";