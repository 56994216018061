import React from "react"
import LazyLoad from "react-lazyload"

import type { Shops } from "../helpers/fetchShops"

import * as styles from "./card.module.scss"

export default function Card({ title, img, percent }: Shops) {
  return (
    <figure className={styles.figure}>
      {percent && <span className={styles.percent}>Скидки до {percent}%</span>}
      <LazyLoad offset={50} once debounce={2000} classNamePrefix="img">
        <img className={styles.img} alt="img" src={img} />
      </LazyLoad>
      <figcaption className={styles.figcaption}>{title}</figcaption>
    </figure>
  )
}
