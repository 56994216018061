import sendRequest from "@ecom/ui/utils/sendRequest"

export interface Shops {
  createdAt: string
  date_end: string
  date_start: string
  descr: string
  id: number
  img: string
  link: string
  percent?: number
  title: string
}

const limit = 10

export default function fetchShops(offset = 0): Promise<Shops[]> {
  return sendRequest(`${process.env.GATSBY_APP_HALVA_API_URL}/v2/halva/stock`, {
    limit,
    offset,
  })
    .then((res: Shops[]) => res)
    .catch(() => {
      throw new Error("Fetch partners failed")
    })
}
