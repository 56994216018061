import React, { useState, useEffect, useRef } from "react"

import SwiperCore, { SwiperOptions, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Container from "@ecom/ui/components/Container"

import Card from "./Card"
import NavBtn from "./NavBtn"

import fetchShops from "./helpers/fetchShops"
import type { Shops } from "./helpers/fetchShops"

import * as styles from "./discountsSwiper.module.scss"

const MAX_SLIDES_COUNT_PER_VIEW = 10

SwiperCore.use([Navigation])

export default function DiscountsSwiper() {
  const isLastRequestRef = useRef(false)
  const [shops, setShops] = useState<Shops[]>([])

  const params: SwiperOptions = {
    navigation: {
      nextEl: `.${styles.btnNext}`,
      prevEl: `.${styles.btnPrev}`,
    },
    slidesPerView: "auto",
  }

  function getShops(offset?: number) {
    fetchShops(offset).then((shops1) => {
      if (shops1.length < MAX_SLIDES_COUNT_PER_VIEW) {
        isLastRequestRef.current = true
      }

      setShops((prev) => prev.concat(shops1))
    })
  }

  useEffect(() => {
    getShops()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onSlideNext(instance: SwiperCore) {
    // внутри события все useState с базовыми значениями
    // здесь их нельзя использовать
    // https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
    if (isLastRequestRef.current) return

    const { realIndex, slides } = instance
    const shopsLen = slides.length

    if (realIndex >= shopsLen - MAX_SLIDES_COUNT_PER_VIEW) {
      getShops(shopsLen)
    }
  }

  return (
    <div className={styles.root}>
      <Container className={styles.swiperOuter}>
        <NavBtn className={styles.btnNext} />
        <NavBtn className={styles.btnPrev} />
        <Swiper {...params} longSwipesRatio={0.01} onSlideNextTransitionEnd={onSlideNext}>
          {shops.map((data) => (
            <SwiperSlide className={styles.slide} key={data.id}>
              <Card {...data} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  )
}
