import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import clsx from "clsx"
import { clear } from "idb-keyval"

import Button from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"

import DiscountsSwiper from "../DiscountsSwiper"

import { getIDBValue, setIDBValue } from "../../utils/idbUtils"

import * as styles from "./finish.module.scss"

const items = [
  <>
    Установите наше расширение
    <br /> и займитесь онлайн-шопингом!
  </>,
  <>
    При входе на сайт партнера мы покажем
    <br /> оповещение с условиями рассрочки
  </>,
  <>
    Вы узнаете о действующих акциях
    <br /> и спецпредложении для Вас
  </>,
]

interface Props {
  head?: React.ReactNode
  subHead?: React.ReactNode
  text?: React.ReactNode
}

const defaultHead = (
  <>
    Поздравляем вам одобрена
    <br />
    карта Халва!
  </>
)

const defaultSubHead = "Ваша халва одобрена!"

export default function Finish({ head = defaultHead, subHead = defaultSubHead, text }: Props) {
  const [choice, setChoice] = useState<string>()

  let headText = head
  let subHeadText = subHead

  useEffect(() => {
    getIDBValue<string>("choice").then((userChoice) => {
      setChoice(userChoice)
      clear().then(() => {
        // чтобы был доступен после обновления страницы
        setIDBValue("choice", userChoice)
      })
    })
    document.cookie = "auth=0; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;"
  }, [])

  let description: React.ReactNode

  if (!text) {
    if (choice === "pickup") {
      headText = "Спасибо!"
      subHeadText = ""
      description = "Наш специалист свяжется с Вами в ближайшее время"
    } else {
      description = (
        <>
          В ближайшее время
          <br className="d-sm-none" /> с Вами свяжется сотрудник банка
          <br /> для уточнения деталей доставки
        </>
      )
    }
  } else {
    description = text
  }

  return (
    <>
      <section className={clsx(styles.congrats, "text-center")}>
        <Container>
          <h2 className={styles.head}>{headText}</h2>
          <h3 className={styles.subHead}>{subHeadText}</h3>
          <p className={styles.description}>{description}</p>
        </Container>
      </section>
      <section className={styles.extension}>
        <Container className={styles.extContainer}>
          <div>
            <h1 className={styles.extHead}>
              Будьте в курсе всех выгодных акций
              <br />
              по Халве!
            </h1>
            <p className={styles.extDesc}>
              Не упустите скидки, акции и подарки
              <br />
              от Халвы и партнеров!
            </p>
            <ul className={styles.list}>
              {items.map((text1, i) => (
                <li className={styles.item} key={i}>
                  {text1}
                </li>
              ))}
            </ul>
            <div className={clsx("formBtnOuter", "text-left")}>
              <Button className="formBtn" href="https://halvacard.ru/shops/actions" target="_blank">
                Продолжить
              </Button>
            </div>
          </div>
          <StaticImage
            className={styles.img_bg}
            alt="halva"
            src="./img/ext_bg.png"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
          />
        </Container>
      </section>
      <section className={styles.stocks}>
        <h2 className={styles.stocksHead}>Акции для Вас</h2>
        <DiscountsSwiper />
      </section>
    </>
  )
}
